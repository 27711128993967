import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Mentors.scss";
import { mentorData } from "../../data/mentorData";
import { ReactComponent as LeftArrow } from "../../assets/images/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/images/rightArrow.svg";
import { ReactComponent as Linkedin } from "../../assets/images/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/images/twitter.svg";

const Mentors = () => {
  const ref = useRef<Slider>(null);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  return (
    <div className="mentors" id="mentors">
      <div className="mentors-header">
        <h1>Mentors.</h1>
        <p>Each week-long skill 'sprint' is a mix of solo and live group-based learning.</p>
      </div>

      <div
        className="left-arrow"
        style={{ cursor: "pointer" }}
        onClick={() => ref.current?.slickPrev()}
      >
        <LeftArrow />
      </div>
      <div
        className="right-arrow"
        style={{ cursor: "pointer" }}
        onClick={() => ref.current?.slickNext()}
      >
        <RightArrow />
      </div>
      <Slider ref={ref} {...settings}>
        {mentorData.map((f, index) => (
          <div key={index.toString()} className="mentors-slider">
            <div className="image">
              <img src={f.image} alt="" />
            </div>
            <div className="content-right">
              <div className="content">
                <h2>{f.name}</h2>
                <p>{f.occupation}</p>
                <p>{f.details}</p>
              </div>
              <div className="icons">
                <Linkedin />
                <Instagram />
                <Twitter />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Mentors;
