export const memoriesData = [
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSz-T7cL1jsAXx7nH_oggpyMYGDXx1EmQrVFw&usqp=CAU",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6F_TotqwxMHTTGAqB9Dkzo2SXswrdzjgOGQ&usqp=CAU",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7Alv3P1rHigEvWWpLZ2ruEL6Ltw2iabSSnA&usqp=CAU",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://i.pinimg.com/originals/8b/20/7a/8b207a9eae9bf63cb4c4ebe3f1c02709.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkgXWUMr_Vcw6KhaHLTy0SNSljgWrMIi5rFQ&usqp=CAU",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://loveshayariimages.in/wp-content/uploads/2021/10/free-Beautiful-Peaceful-Whatsapp-Dp-Images.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://t4.ftcdn.net/jpg/05/05/53/71/360_F_505537109_3PgxT8ramD26MDkySRhxhEW5pVavDwLI.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
];
