import React from "react";
import "./Benifits.scss";
import { ReactComponent as Fire } from "../../assets/icons/fire.svg";
import { ReactComponent as Group } from "../../assets/icons/Group.svg";
import Infosys from "../../assets/logos/infosys.svg";
import Mongo from "../../assets/logos/mongo.svg";
import Dehustle from "../../assets/logos/dehustle.svg";
import GraphQL from "../../assets/logos/graphQl.svg";
import GoDaddy from "../../assets/logos/goDaddy.svg";
import BillDesk from "../../assets/logos/BillDesk.svg";
import Graph from "../../assets/images/graph.png";
const Benifits = () => {
  const renderGraph = (
    <>
      <h2>BLOCKCHAIN DEVELOPER</h2>
      <h4>Hiring Companies</h4>
      <div className="logos-flex">
        <div className="logos">
          <img src={Infosys} alt="" />
          <img src={Dehustle} alt="" />
          <img src={GoDaddy} alt="" />
        </div>
        <div className="logos">
          <img src={Mongo} alt="" />
          <img src={GraphQL} alt="" />
          <img src={BillDesk} alt="" />
        </div>
      </div>
    </>
  );
  return (
    <div className="benifit" id="benifits">
      <div className="mx">
        <div className="benifits">
          <h1>Industry Benifits</h1>
          <p>
            The Blockchain market is expected to reach USD 39.7B by 2025, at a
            growth rate of 67.3%, indicating the increased adoption of
            blockchain application.
          </p>
        </div>
        <div className="industry-benifits">
          <div className="benifits-card">
            <h2>BLOCKCHAIN</h2>
            <h4>Industry Growth</h4>
            <Fire />
            <h3>MOST TRENDING SKILL</h3>
            <p>
              With <span>5000+</span> job openings in the U.S. and{" "}
              <span>1600+</span> worldwide.
            </p>
            <Group />
            <h3>CAGR AT 67.3%</h3>
            <p>Indicating increasing need of simplifying business functions.</p>
          </div>
          <div className="benifits-card">{renderGraph}</div>
          <div className="benifits-card">
            <h2>BLOCKCHAIN DEVELOPER</h2>
            <h4>Annual Salary</h4>
            <img src={Graph} alt="" />
          </div>
        </div>
        <div className="responsive">{renderGraph}</div>
      </div>
    </div>
  );
};

export default Benifits;
