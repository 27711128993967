import React, { useState } from "react";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Map } from "../../assets/icons/map-pin.svg";
import { ReactComponent as Seats } from "../../assets/icons/robo.svg";
import "./Workshops.scss";
import { workshopData } from "../../data/workshopData";
import { ReactComponent as Prev } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as Next } from "../../assets/icons/right-arrow.svg";
import Button from "../Button";

const getXPosition = (index: number, active: number) => {
  if (index === active) return 0;
  if (index === active + 1) return "15%";
  if (index === active + 2) return "25%";
  return 0;
};

const getScale = (index: number, active: number) => {
  if (index === active) return 1;
  if (index === active + 1) return 0.8;
  if (index === active + 2) return 0.7;
  return 0.6;
};

const getZindex = (index: number, active: number) => {
  if (index === active) return 3;
  if (index === active + 1) return 2;
  if (index === active + 2) return 1;
  return 0;
};

const Memories: React.FC = () => {
  const [active, setActive] = useState(0);

  const handlePrev = () => setActive((a) => (a === 0 ? 0 : a - 1));

  const handleNext = () =>
    setActive((a) =>
      a === workshopData.length - 1 ? workshopData.length - 1 : a + 1
    );

  const handleSwipe = (e: any) => {
    if (e.dir === "Left") handleNext();
    if (e.dir === "Right") handlePrev();
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="workshop-container" id="workshops">
      <div className="workshop-header">
        <h1>Upcoming Workshops.</h1>
        <p>The level you reach is an endorsement of your business impact.</p>
      </div>
      <div className="workshop">
        <div className="workshop-card_wrapper" {...handlers}>
          {workshopData.map((f, index) => (
            <motion.div
              key={index.toString()}
              className={
                index === active ? "workshop-card active" : "workshop-card"
              }
              initial={{ scale: 0, x: -10 }}
              animate={{
                x: getXPosition(index, active),
                scale: getScale(index, active),
                zIndex: getZindex(index, active),
              }}
              exit={{ left: -100 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 30,
              }}
            >
              <div className="image">
                <img src={f.image} alt="" />
              </div>
              <div className="content">
                <div className="grid1">
                  <div>
                    <h1>{f.title}</h1>
                  </div>
                  <div className="dates-venues">
                    <div className="date">
                      <Calendar />
                      <p>{f.date}</p>
                    </div>
                    <div className="date">
                      <Map />
                      <p>{f.venue}</p>
                    </div>
                  </div>
                </div>
                <div className="grid2">
                  <div className="seats">
                    <span>available seats</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {" "}
                      <Seats />
                      100
                    </div>
                  </div>
                  <Button children="Book Seats" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="workshop-controls">
        <div
          className={active === 0 ? "arrow inactive" : "active"}
          onClick={() => setActive((a) => (a === 0 ? 0 : a - 1))}
        >
          <Prev className="prev" />
        </div>
        <div
          className={
            active === workshopData.length - 1 ? "arrow inactive" : "active"
          }
          onClick={() =>
            setActive((a) =>
              a === workshopData.length - 1 ? workshopData.length - 1 : a + 1
            )
          }
        >
          <Next />
        </div>
      </div>
    </div>
  );
};

export default Memories;
