import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ReactComponent as Logo } from "../../assets/logos/logo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";
import Button from "../Button";
import "./Header.scss";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openMenu]);

  const renderLinks = (
    <div className="content">
      <AnchorLink href="#benifits" onClick={() => setOpenMenu((m) => !m)}>
        Benifits
      </AnchorLink>
      <AnchorLink href="#workshops" onClick={() => setOpenMenu((m) => !m)}>
        Workshops
      </AnchorLink>
      <AnchorLink href="#mentors" onClick={() => setOpenMenu((m) => !m)}>
        {" "}
        Mentors
      </AnchorLink>
      <AnchorLink href="#memories" onClick={() => setOpenMenu((m) => !m)}>
        Memories
      </AnchorLink>
    </div>
  );
  const renderButton = (
    <>
      <Button children={"Download Broucher"} className={"primary"} />
    </>
  );
  return (
    <>
      <header>
        <div className="mx header">
          <div className="navbar-logo">
            <Logo />
          </div>
          <div className="menu-icon" onClick={() => setOpenMenu((m) => !m)}>
            {openMenu ? <RiCloseFill /> : <GiHamburgerMenu />}
          </div>
          <div className="content">{renderLinks}</div>
          <div className="button">{renderButton}</div>
        </div>
      </header>
      {openMenu && (
        <div className="media-header">
          {renderLinks}
          {renderButton}
        </div>
      )}
    </>
  );
};

export default Header;
