export const workshopData = [
  {
    image:
      "http://www.goodmorningimagesdownload.com/wp-content/uploads/2020/06/Alone-Boys-Girls-Images-6.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://www.adobe.com/content/dam/cc/us/en/products/creativecloud/stock/stock-riverflow1-720x522.jpg.img.jpg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
  {
    image:
      "https://www.udacity.com/blog/wp-content/uploads/2020/11/Digital-Marketing-Explained_Blog-scaled.jpeg",
    date: "23, Jan 2023",
    venue: "Tambaram, Chennai",
    title: "Ice-Breaking ",
  },
];
