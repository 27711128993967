import React from "react";
import { Hero, Benifits, Mentors, Workshops, Memories, Testimonials } from "../../component";

const Home = () => {
  return (
    <div className="mx">
      <Hero />
      <Benifits />
      <Workshops />
      <Mentors />
      <Memories />
      <Testimonials />
    </div>
  );
};

export default Home;
