import React, { useRef } from "react";
import Slider from "react-slick";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Hero.scss";

const Hero = () => {
  const ref = useRef<Slider>(null);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };
  return (
    <div className="slider">
      <div
        className="left-arrow"
        style={{ cursor: "pointer" }}
        onClick={() => ref.current?.slickPrev()}
      >
        <LeftArrow />
      </div>
      <div
        className="right-arrow"
        style={{ cursor: "pointer" }}
        onClick={() => ref.current?.slickNext()}
      >
        <RightArrow />
      </div>
      <Slider ref={ref} {...settings}>
        <div className="slides">
          <h1>One Day</h1>
          <h2>FREE Workshop</h2>
        </div>
        <div className="slides">
          <h1>One Day</h1>
          <h2>FREE Workshop</h2>
        </div>
        <div className="slides">
          <h1>One Day</h1>
          <h2>FREE Workshop</h2>
        </div>
        <div className="slides">
          <h1>One Day</h1>
          <h2>FREE Workshop</h2>
        </div>
      </Slider>
    </div>
  );
};

export default Hero;
