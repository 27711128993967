import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Photo from "../../assets/images/photo.png";
import Logo from "../../assets/icons/dehustle.svg";
import Quotes from "../../assets/images/quotes.svg";
import Google from "../../assets/images/google.png";
import Star from "../../assets/icons/fullColorStar.svg";
import OneSideStar from "../../assets/icons/oneSideColor.svg";
import WhiteStar from "../../assets/icons/whiteColorStar.svg";
import { TestimonialData } from "../../data/testimonialData";
import MediaPhoto from "../../assets/images/boy.png";
import "./Testimonials.scss";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    arrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="testimonials" id="testimonials">
      <div className="mx">
        <div>
          <h1>Testimonials.</h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s,
          </p>
        </div>
        <Slider {...settings}>
          {TestimonialData.map((f, index) => (
            <div key={index.toString()} className="testimonial-slider">
              <div className="content">
                <div className="google">
                  <div>
                    <img src={Google} alt="" />
                  </div>
                  <div className="google-rating">
                    <p>google rating</p>
                    <div className="stars">
                      <img src={Star} alt="" />
                      <img src={Star} alt="" />
                      <img src={Star} alt="" />
                      <img src={OneSideStar} alt="" />
                      <img src={WhiteStar} alt="" />
                    </div>
                  </div>
                </div>
                <div className="quotes">
                  <img src={Quotes} alt="" />
                </div>
                <p>{f.content}</p>
                <div className="name">
                  <div className="roles">
                    <h2>{f.name}</h2>
                    <p>{f.role}</p>
                  </div>
                  <img src={Logo} alt="" />
                </div>
              </div>
              <div className="image">
                <img src={Photo} alt="" />
                <div className="photo">
                  <img src={f.image} alt="" />
                </div>
              </div>
              <div className="responsive-image">
                <img src={MediaPhoto} alt="" />
                <div className="photo">
                  <img src={f.image} alt="" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
