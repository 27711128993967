import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Footer, Header, Workshops } from "./component";
import { Home } from "./pages";
const App = () => {
  return (
    <Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="workshop" element={<Workshops />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
    </Fragment>
  );
};

export default App;
