export const TestimonialData = [
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    name: "Arunachalam",
    role: "Frontend Developer",
    image:
      "https://images.mubicdn.net/images/cast_member/2184/cache-2992-1547409411/image-w856.jpg?size=800x",
  },
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    name: "Sam Raj",
    role: "Frontend Developer",
    image:
      "https://www.thewikifeed.com/wp-content/uploads/2021/10/chris-evans-1.jpg",
  },
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    name: "Ibrahim",
    role: "Frontend Developer",
    image:
      "https://images.mubicdn.net/images/cast_member/2184/cache-2992-1547409411/image-w856.jpg?size=800x",
  },
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    name: "SimeonPaul",
    role: "Frontend Developer",
    image:
      "https://www.thewikifeed.com/wp-content/uploads/2021/10/chris-evans-1.jpg",
  },
  {
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    name: "Arunachalam",
    role: "Frontend Developer",
    image:
      "https://i.insider.com/5a43c31e8ba89220008b45ca?width=750&format=jpeg&auto=webp",
  },
];
