import React, { ButtonHTMLAttributes, ReactNode } from "react";
import "./Button.scss";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary";
  children: ReactNode;
}
const Button: React.FC<IButton> = ({ children, variant = "primary" }) => {
  return <button className={`btn-${variant}`}>{children}</button>;
};

export default Button;
