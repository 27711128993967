import React, { useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Map } from "../../assets/icons/map-pin.svg";
import "./Memories.scss";
import { memoriesData } from "../../data/data";
import { useSwipeable } from "react-swipeable";
import { ReactComponent as Prev } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as Next } from "../../assets/icons/right-arrow.svg";

const Memories = () => {
  const [active, setActive] = useState(0);

  const handlePrev = () => setActive((a) => (a === 0 ? 0 : a - 1));

  const handleNext = () =>
    setActive((a) =>
      a === memoriesData.length - 1 ? memoriesData.length - 1 : a + 1
    );

  const handleSwipe = (e: any) => {
    if (e.dir === "Left") handleNext();
    if (e.dir === "Right") handlePrev();
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipe,
  });

  return (
    <div className="memories-container" id="memories">
      <div className="memories-header">
        <h1>Memories.</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s,
        </p>
      </div>
      <div className="memories">
        <div className="memories-card_wrapper" {...handlers}>
          {memoriesData.map((f, index) => (
            <motion.div
              key={index.toString()}
              className={
                index === active ? "memories-card active" : "memories-card"
              }
              initial={{ scale: 0, x: -10 }}
              animate={{
                x: 0,
                left: `${(index - active) * 34.2}vw`,
                scale: index === active ? 1 : 0.8,
                opacity: index === active ? 1 : 0.7,
                zIndex: index === active ? 1 : 0,
              }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 30,
              }}
            >
              <div className="image">
                <img src={f.image} alt="" />
              </div>
              <div className="memories-content">
                <div className="content">
                  <h1>
                    {f.title} <span>Day</span>
                  </h1>
                </div>
                <div className="dates-venues">
                  <div className="date">
                    <Calendar />
                    <p>{f.date}</p>
                  </div>
                  <div className="date">
                    <Map />
                    <p>{f.venue}</p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="controls">
        <div
          className={active === 0 ? "arrow inactive" : "active"}
          onClick={() => setActive((a) => (a === 0 ? 0 : a - 1))}
        >
          <Prev className="prev" />
        </div>
        <div
          className={
            active === memoriesData.length - 1 ? "arrow inactive" : "active"
          }
          onClick={() =>
            setActive((a) =>
              a === memoriesData.length - 1 ? memoriesData.length - 1 : a + 1
            )
          }
        >
          <Next />
        </div>
      </div>
    </div>
  );
};

export default Memories;

// {memoriesData.map((f) => (
//   <div className="workshop">

//   </div>
// ))}
