export const mentorData = [
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ0SiEWRAcaerxyTx8ZnOINw0XA_6qma7rKQ&usqp=CAU",
    name: "Arun",
    occupation: "Professor, Computer Science and Engineering at IIT Kanpur",
    details:
      "Sandeep Shukla is the Coordinator, Interdisciplinary Center for Cyber Security and Cyber Defense of Critical Infrastructure, IIT Kanpur. He has a Ph.D. & MS in Computer science from the State University of New York.",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmaLerNJiCpkIBl_BtWZGl-VP4eHmtEfVecogcyB5GAmlMQHX1lCcxEBvD54d2k-qZdjI&usqp=CAU",
    name: "Simeon",
    occupation: "Professor, Computer Science and Engineering at IIT Kanpur",
    details:
      "Sandeep Shukla is the Coordinator, Interdisciplinary Center for Cyber Security and Cyber Defense of Critical Infrastructure, IIT Kanpur. He has a Ph.D. & MS in Computer science from the State University of New York.",
  },
  {
    image:
      "https://i.pinimg.com/736x/1a/a8/49/1aa8494945fd967d167d469746f0f2ac.jpg",
    name: "Sam",
    occupation: "Professor, Computer Science and Engineering at IIT Kanpur",
    details:
      "Sandeep Shukla is the Coordinator, Interdisciplinary Center for Cyber Security and Cyber Defense of Critical Infrastructure, IIT Kanpur. He has a Ph.D. & MS in Computer science from the State University of New York.",
  },
  {
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ0SiEWRAcaerxyTx8ZnOINw0XA_6qma7rKQ&usqp=CAU",
    name: "Ibrahim",
    occupation: "Professor, Computer Science and Engineering at IIT Kanpur",
    details:
      "Sandeep Shukla is the Coordinator, Interdisciplinary Center for Cyber Security and Cyber Defense of Critical Infrastructure, IIT Kanpur. He has a Ph.D. & MS in Computer science from the State University of New York.",
  },
];
